<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'App',
  data () {
    return {
      section: 'Head'
    }
  },
  mounted () {
    this.$nextTick(() => {

    })
  },
  methods: {
    logout () {
      let hasWonde = false
      if (this.$store.state.user && this.$store.state.user.wonde_session) {
        hasWonde = true
      }

      this.$store.commit('SET_USER', null)
      this.$store.commit('SET_TOKEN', null)

      if (window.localStorage) {
        window.localStorage.setItem('user', null)
        window.localStorage.setItem('token', null)
      }

      if (hasWonde) {
        window.location.href = 'https://edu.wonde.com/sso/logout?client_id=670251'
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss">
  $aqua: #6ac8dd;
  $info: $aqua;
  @import "~bulma/sass/utilities/_all";

// Import common variables
@import '@/edshed-common/styles/variables';
@import '@/edshed-common/styles/fonts';

$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "link": ($link, $link-invert),
    "number": ($number, $number-invert),
    "quiz": ($quiz, $quiz-invert),
    "combined": ($combined, $combined-invert),
    "phonics": ($phonics, $phonics-invert)
);

  @import "~bulma";

  @import "~buefy/src/scss/buefy";

  @import url('https://fonts.googleapis.com/css?family=Muli');

  body, h1, h2, h3, h4, h5, h6 {
    font-family: 'Muli';
  }

  $primary: $turquoise;
  $link: $blue;

  $aqua: #6ac8dd;
  $info: $aqua;

  #idFrame {
    display: none;
  }

  .main-header .sidebar-toggle {
    position: absolute;
    height: 50px;
  }
  .skin-black-light .main-header .navbar .sidebar-toggle:hover {
    background-color: #fff;
  }

  .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
    background-color: $link;
  }

  body {
    height: initial;
  }

  .box {
    border-top-width: 0;
  }

  .box.box-danger {
    border-top-color: white;
  }
  .box.box-warning {
    border-top-color: white;
  }
  .box.box-primary {
    border-top-color: white;
  }
  .box.box-info {
    border-top-color: white;
  }
  .box.box-link {
    border-top-color: white;
  }
  .box.box-success {
    border-top-color: white;
  }
  .bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
    background-color: $success !important;
  }
  .bg-purple {
    background-color: $purple !important;
  }
  .bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
    background-color: $warning !important;
  }
  .bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
    background-color: $aqua !important;
  }

  .bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
    background-color: $danger !important;
  }

  .tag.type-free {
    background-color: $success;
    color: white;
  }

  .tag.type-personal {
    background-color: $link;
    color: white;
  }

  .tag.type-premium {
    background-color: $danger;
    color: white;
  }

  .tag.type-standard {
    background-color: $primary;
    color: white;
  }

  .content ul.dropdown-menu {
    list-style: none !important;
  }

  .modal-dialog .modal-content {
    /* overflow:initial; */
  }

  .content {
    min-height: auto;
  }
  .modal-dialog {
    margin: 10px;
  }
.modal-dialog .modal-content  {
  margin-left: 0 !important;
  /* max-height: inherit !important; */
}

.skin-blue .sidebar-menu>li.active>a {
  border-left-color: transparent;
}

.skin-blue .sidebar-menu>li:hover>a {
  border-left-color: $info;
}

.box.box-white {
  border-top: 0px solid #d2d6de !important;
}

.alert.alert-warning {
  border: 0px solid white;
}

  /* .content .bootstrap-datetimepicker-widget ul {
    list-style: none !important;
  }

  .content .pagination ul {
    list-style: none;
    margin: 0;
  } */

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
  html {
    overflow: auto;
    background-color: #2e323a;
    /* height: 100%; */
  }

  body {
    overflow: visible;
    background-color: #f8f8f8;
  }

  .modal {
    z-index: 999;
  }

  fieldset {
    margin: 20px 0;
  }

  .dropdown-menu {
    z-index: 991;
    padding: 0;
  }

  .datepicker .dropdown-menu {
    display: block;
  }

  .main-header .sidebar-toggle:before {
    content: "\f0c9";
    font-family: "Font Awesome 5 Solid";
    display:none;
  }

  .loading-overlay {
    z-index: 10000;
  }

  .btn-group {
    display: inline-flex;
  }

  .container-table {
    height: 100%;
    display: table;
    color: white;
  }
  .vertical-center-row {
      display: table-cell;
      vertical-align: middle;
  }
  .vertical-20p {
    padding-top: 20%;
  }
  .vertical-10p {
    padding-top: 10%;
  }
  .logo {
    /* width: 15em; */
    padding: 1em 1em 0;
  }

  .sublogo {
    width: 10em;
    padding: 1em 1em 0;
  }

  .sublogo.mathslogo{
    padding-top: 0.6em;
  }

  .logo.mathslogo{
    padding-top: 0.5em;
  }
  .loginForm .input-group {
    padding-bottom: 1em;
    height: 4em;
  }
  .input-group input {
    height: 4em;
  }

  .stepwizard-step {
    width: 33%;
  }

  .stepwizard-step p {
    margin-top: 10px;
  }

  .stepwizard-row {
    display: table-row;
  }

  .stepwizard {
    display: table;
    width: 100%;
    position: relative;
  }

  .stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }

  .stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: 0;

  }

  .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }

  .Password__badge {
    box-sizing: content-box;
  }

  .modal .modal-content.box {
    max-width: 640px;
  }

  .btn-box-tool {
    color: #4a4a4a;
    font-size: 1.2em;
  }

  .label {
    text-align: left;
  }

  .loading-overlay.is-full-page {
    z-index: 9999;
  }

  @media only print {
    html {
      overflow: initial;
    }
  }

  .sidebar-mini .main-sidebar a.logo-min {
      display: none;
    }

  .sidebar-mini.sidebar-open aside {
    margin-top: 50px;
  }

  .ct-activity .ct-series-a .ct-bar {
    stroke: #e3e634 !important;
  }
  .ct-activity .ct-series-b .ct-bar {
    stroke: #5017b9 !important;
  }

  /* Fix menu behaviour */
  @media (min-width: 768px) {
    /* .sidebar-mini.sidebar-collapse .main-sidebar .user-panel>.info, .sidebar-mini.sidebar-collapse .sidebar-form, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span, .sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>.pull-right, .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
      display: none;
      -webkit-transform: translateZ(0);
    } */

    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
      display:blocK !important;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span.icon {
      display: block !important;
      position:initial !important;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span.icon {
      display: block !important;
      -webkit-transform: translateZ(1) !important;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar p.schoolName {
      display: none;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar p.schoolName, .sidebar-mini.sidebar-collapse .main-sidebar a.logo-maxi {
      display: none;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar p.schoolName, .sidebar-mini.sidebar-collapse .main-sidebar a.logo-maxi {
      display: none;
    }

    .sidebar-mini.sidebar-collapse .main-sidebar a.logo-min {
      display:block;
    }

    .sidebar-mini.sidebar-open aside {
      margin-top: 0;
    }
  }
</style>
