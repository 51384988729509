import { GameTheme, JsonString, MixedContent, StoredImage, UUID } from './common'
import { Avatar } from './avatar'
import { QuestionSetAssessmentType } from './quiz'
import { GrammarGameIdent } from './grammar'
import { ArrayElement, GivenAnswer, ISO8601Date, PhonicsHomeworkOptions, Pupil, QuestionDataType, SpellingList } from '.'

export type TestStatusOptions = 'not_started' | 'in_progress' | 'completed'
export interface HomeworkInfo {
  id: number
  title: string
  owner_id: number
  list_id: number | null
  list_ident: string
  created: ISO8601Date
  available_from: ISO8601Date
  expiry: ISO8601Date | null
  number_plays: number | null
  hide_other_lists: number
  number: number
  type: HomeworkType // format of activity
  subtype?: HomeworkSubType
  theme: GameTheme // product associated with activity - e.g. type may be 'lesson' but theme could be 'phonics'
  quiz_params: HomeworkParams | null
  set_id: number | null
  set_week_number: number | null
  stage: number | null
  list_name: string
  owner_name: string
  owner_username: string
  test_mode: boolean
  test_status: TestStatusOptions | null
  is_assessment: boolean
  assessment_type: QuestionSetAssessmentType | null
}

export type TeacherHomework<TPupils extends boolean | undefined, TGroups extends boolean | undefined, TTeachers extends boolean | undefined> = HomeworkInfo & (TPupils extends true ? PupilsForHomework : {}) & (TGroups extends true ? GroupsForHomework : {}) & (TTeachers extends true ? { teachers: TeacherForHomework[] } : {})

export interface PupilsForHomework {
  pupils: string | null
}

export interface GroupsForHomework {
  groups: string
}
export interface TeacherForHomework {
  username: string
  avatar: JsonString<Avatar>
  avatar_hash: string | null
  display_name: string
  user_id: number
}

export interface PupilHomework extends HomeworkInfo {
  games_played: number
  status_score: number
  status_score_max: number
  status_scores_list: string
}

export type HomeworkModel<TPupils extends boolean | undefined, TGroups extends boolean | undefined, TTeachers extends boolean | undefined> = TeacherHomework<TPupils, TGroups, TTeachers> | PupilHomework

export type SpellingSessionResult = {
  word: string
  correct: boolean
  answer_given: string
  dictionary_id: number
}

export type NumberSessionResult = {
  answer_id: number,
  question: string
  time_taken: number
  number_game_id: number
  correct: boolean
  answer_given: string
}

export type QuizSessionResult = {
  answer_id: UUID
  type: QuestionDataType
  question: MixedContent
  question_image: StoredImage | null
  correct: boolean
  answer_given: GivenAnswer['answerGiven']
  correct_answer: GivenAnswer['correctAnswer']
  score: number
  time_taken: number
  question_id: number
}

export interface HomeworkSessionData {
  date: ISO8601Date | null
  number_of_questions: number
  number_answered: number
  number_correct: number
  session_results?: (SpellingSessionResult | QuizSessionResult | NumberSessionResult)[]
  gameplay_id?: number | UUID
  user_id: number
  difficulty: number
  rank_score?: number // number games wil calculate this explicitly, for other games times it is implicit from the above properties
}

export interface GrammarHomeworkSessionData {
  date: ISO8601Date
  session_id: number
  lesson_id: number
  game_ident: GrammarGameIdent
  user_id: number
  total_score: number
  earnings: number
}

export interface HomeworkData {
  name: string
  username: string
  id: number
  games_played: string
  scores: string
  avg: number
  avg_diff: string
  status_score: number
  status_score_max: number
  status_scores_list: string
  earnings?: number
  score?: number
  total_score?: number
  set_id: number | null
  set_week_number: number | null
  test_status?: TestStatusOptions
}

export interface HomeworkComponentData {
  labels: Array<'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday'>
  series: Array<{ name: string, data: number[] }>
}

export type HomeworkParams = QuizHomeworkOptions | PhonicsHomeworkOptions | SpellingHomeworkOptions

export interface AddHomeworkOptions {
  list: string | null
  type: HomeworkType
  subtype: HomeworkSubType
  expiry: Date | null
  availableFrom: Date | null
  gamesRequired: number
  limitLists: number
  testMode: boolean
  title: string,
  quiz_params?: HomeworkParams | null,
  set_id?: number | null
  set_week_number?: number | null
  owner_id?: number
}

export function hasQuizOptions (params: HomeworkParams | null): params is QuizHomeworkOptions {
  if (params === null) {
    return false
  }

  if ((params as QuizHomeworkOptions).jumble_questions !== undefined || (params as QuizHomeworkOptions).count !== undefined) {
    return true
  } else {
    return false
  }
}

export function hasSpellingOptions (params: HomeworkParams | null): params is SpellingHomeworkOptions {
  if (params === null) {
    return false
  }

  if ((params as SpellingHomeworkOptions)?.phonics !== undefined) {
    return true
  } else {
    return false
  }
}

export function hasPhonicsOptions (params: HomeworkParams | null): params is PhonicsHomeworkOptions {
  if (params === null) {
    return false
  }

  if ((params as PhonicsHomeworkOptions).grapheme !== undefined || (params as PhonicsHomeworkOptions).game !== undefined) {
    return true
  } else {
    return false
  }
}

export interface HomeworkSetTestOptions {
  enforce_phonics: boolean
  include_practices: boolean
  test_start_hour: number
  test_start_minute: number
  test_end_hour: number
  test_end_minute: number
  test_day: number
  phonics_difficulty?: number
  shuffle_words: boolean
  test_hide_other_lists: boolean
  practice_hide_other_lists?: boolean
}

export interface AddHomeworkSetRequest {
  homeworks: AddHomeworkOptions[]
  group_ids: number[]
  start_date: Date
  title: string
  scheme_variant: string
  stage: number
  stage_category: string | null
  hide_other_lists: number
  number_plays: number | null
  phonics: 0 | 1 | 2
  test_options: HomeworkSetTestOptions | null
}

export interface EditHomeworkSetRequest extends AddHomeworkSetRequest {
  homeworks: Array<AddHomeworkOptions & { id?: number }>
}

export interface HomeworkSetGroup {
  set_id: number
  group_id: number
  group_name: string
}

export interface HomeworkSetInfo {
  id: number
  title: string
  owner_id: number
  groups: HomeworkSetGroup[]
  start_date: Date
  scheme_variant: string
  stage: number
  stage_category: string | null
  created: Date
  number_plays: number | null
  hide_other_lists: number
  phonics: 0 | 1 | 2
  school_id: number
  test_options: HomeworkSetTestOptions | null
}

export interface HydratedHomeworkSetInfo extends HomeworkSetInfo {
  homeworks: (HomeworkInfo & { list: SpellingList | null })[]
}

export interface HomeworkSetCompetenceData {
  set_id: number,
  groups: HomeworkSetGroup[]
  users: HomeworkSetCompetenceUser[]
  lists: HomeworkSetCompetenceList[]
  grid: {
    user_id: number
    list_id: number
    competence_score: number
  }[]
}

export type HomeworkSetCompetenceUser = Pick<Pupil, 'id' | 'name' | 'username'>

export type HomeworkSetCompetenceList = Pick<SpellingList, 'id' | 'ident' | 'list' | 'title'>

export interface QuizHomeworkOptions {
  count?: number
  jumble_questions?: QuizQuestionOrdering
}

export type QuizQuestionOrdering = boolean | 'ordered'

export interface SpellingDiagnosticOptions {
  stage: number
  term: number
}

export interface SpellingHomeworkOptions {
  phonics?: boolean
  enforcePhonics?: boolean
  phonicsTestDifficulty?: number
  testShuffleWords?: boolean
  diagnosticOptions?: SpellingDiagnosticOptions
}

export const HomeworkType = ['spelling', 'number', 'quiz', 'lesson', 'phonics', 'grammar'] as const
export type HomeworkType = typeof HomeworkType[number]

export const HomeworkQuizSubType = ['mathshed', 'spag'] as const
export type HomeworkQuizSubType = typeof HomeworkQuizSubType[number]
export const HomeWorkLessonSubType = ['mathshed', 'phonics-book'] as const
export type HomeWorkLessonSubType = typeof HomeWorkLessonSubType[number]
export const HomeworkSpellingSubType = ['spelling', 'sentences', 'definitions', 'beekeeper', 'diagnostic'] as const
export type HomeworkSpellingSubType = typeof HomeworkSpellingSubType[number]
export const HomeworkSubType = [...HomeworkQuizSubType, ...HomeWorkLessonSubType, ...HomeworkSpellingSubType] as const
export type HomeworkSubType = typeof HomeworkSubType[number] | null

export type HomeworkTheme = 'spelling' | 'number' | 'quiz' | 'phonics'

export const HomeworkStatus = ['active', 'all', 'pending', 'expired'] as const
export type HomeworkStatus = ArrayElement<typeof HomeworkStatus>

export interface HomeworkFilters<T extends boolean | undefined, U extends boolean | undefined, V extends boolean | undefined> {
  user_id: number // the user you are fetching homework for
  status?: HomeworkStatus
  id?: number // homework id
  group_id?: number // group assigned to homework
  all_users?: boolean
  include_invited?: boolean
  include_pupils?: T
  include_groups?: U
  include_teachers?: V
  has_licence?: boolean // should we filter out hw for which the user does not currently have a licence
  type?: HomeworkType | null
  test?: boolean // only include test assignments
}

export type HomeworkUserFilters<T extends boolean | undefined, U extends boolean | undefined, V extends boolean | undefined> = Omit<HomeworkFilters<T, U, V>, 'user_id'>

export interface SpellingDiagnosticSchemeListInfo {
  id: number
  ident: string
  sCode: string
  name: string
}
export interface SpellingDiagnosticWord {
  dictionaryId: number
  text: string
  skillName: string
  schemeListIsForSkill: boolean
  schemeList?: SpellingDiagnosticSchemeListInfo
  skillUrl?: string
  wordReused: boolean
}

export interface EndOfTermDiagnosticData {
  stage: number
  term: number
  test_status: TestStatusOptions
  test_data: HomeworkSessionData
  assignment_id: number
  assignment_title: string
}

export interface PupilDiagnosticData {
  id: number
  name: string
  username: string
  diagnostics: EndOfTermDiagnosticData[]
}

export interface QuestionSetTestsFilters {
  stage?: '1' | '2' | '3' | '4' | '5' | '6'
  is_assessment?: boolean
  assessment_type?: QuestionSetAssessmentType
}
export interface PupilQuestionSetTestInfo {
  homework_id: number
  question_set_ident: string
  question_set_title: string
  completed_on: ISO8601Date
  total_questions: number
  correct_answers: number
  is_assessment: boolean
  assessment_type: QuestionSetAssessmentType | null
}

export interface PupilQuestionSetTestsData {
  id: number
  username: string
  tests: PupilQuestionSetTestInfo[]
}
