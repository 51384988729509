import { UnreachableCaseError } from 'ts-essentials'
import { ISO8601Date } from '../common'
import { Currency } from '../currency'
import { SchoolOrgType } from '../school'
import { NoteInfo } from '../notes'
import { SubscriptionProductType } from './plans'
import { StripeInvoiceAccountRegion, Subscription } from '.'

export const SubscriptionCancellationReason = ['switched_product', 'other', 'school_subscribed', 'ex_teacher', 'financial_constraint', 'account_change'] as const
export type SubscriptionCancellationReason = typeof SubscriptionCancellationReason[number]

interface CancelSubRequestBase {
  improvements: string
}

interface CancelSubRequestReason extends CancelSubRequestBase {
  reason: Extract<SubscriptionCancellationReason, 'school_subscribed' | 'ex_teacher' | 'financial_constraint' | 'account_change'>
}

interface CancelSubRequestSwitchedProduct extends CancelSubRequestBase {
  reason: 'switched_product'
  product: string
}

interface CancelSubRequestOther extends CancelSubRequestBase {
  reason: 'other'
  other: string
}

export type CancelSubRequest = CancelSubRequestOther | CancelSubRequestSwitchedProduct | CancelSubRequestReason

interface CancellationInfoBase {
  cancellation_id: number
  subscription_id: number
  cancellation_date: ISO8601Date
  created_date: ISO8601Date
  expiry_date: ISO8601Date
  currency: Currency
  school_id: number
  school_name: string
  org_type: SchoolOrgType
  account_region: StripeInvoiceAccountRegion
  products: {
    plan_id: number
    sku: string
    product_id: number
    product_type: SubscriptionProductType
  }[]
  improvements: string
}

interface CancellationBasicInfo extends CancellationInfoBase {
  reason: Extract<SubscriptionCancellationReason, 'school_subscribed' | 'ex_teacher' | 'financial_constraint' | 'account_change'>
}

interface CancellationOtherInfo extends CancellationInfoBase {
  reason: 'other'
  other_feedback: string
}

interface CancellationSwitchedProductInfo extends CancellationInfoBase {
  reason: 'switched_product'
  switched_to_product: string
}

export type CancellationInfo<P extends { notes?: boolean | undefined }> = (CancellationBasicInfo | CancellationOtherInfo | CancellationSwitchedProductInfo) & (P['notes'] extends true ? { notes: NoteInfo[] } : {})

export interface GetSubscriptionCancellationReasonsFilters {
  start_date?: Date
  end_date?: Date
  product_id?: number[]
  product_type?: SubscriptionProductType[]
  org_type?: SchoolOrgType[]
  plan_id?: number[]
  currency?: Currency[]
  reasons?: SubscriptionCancellationReason[]
  account_region?: StripeInvoiceAccountRegion[]
}

export interface CancelSubscriptionResponse {
  subscription: Subscription
  cancellation: CancellationInfo<{ notes: false }>
}

export interface CancellationAggregateData {
  reason: SubscriptionCancellationReason
  count: number
}

export function reasonToLabel (reason: SubscriptionCancellationReason) {
  switch (reason) {
    case 'switched_product':
      return 'Switched Product'
    case 'other':
      return 'Other'
    case 'school_subscribed':
      return 'Has school subscription'
    case 'ex_teacher':
      return 'Left Education'
    case 'financial_constraint':
      return 'Financial Constraint'
    case 'account_change':
      return 'Staying with EdShed - Account change'
    default:
      throw new UnreachableCaseError(reason)
  }
}
