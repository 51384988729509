/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import { ServerEnv } from '../servers/types'
import { AccessDeniedError } from './types'

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

let _initialised = false
/** This is set when custom handling of errors is required by this application */
let _authErrorHandler: (() => void) | null = null
let _receiveApiTarget: ((env: ServerEnv) => void) | null = null

export function initApi (baseUrl: string, authErrorHandler: (() => void) | null, receiveApiTarget: ((env: ServerEnv) => void) | null, clientType: string | undefined, clientVersion: string | undefined) {
  _initialised = true
  _authErrorHandler = authErrorHandler
  _receiveApiTarget = receiveApiTarget

  axios.defaults.withCredentials = true // Needed for CORS
  axios.defaults.baseURL = baseUrl

  if (clientType) {
    axios.defaults.headers.common['X-Client-Type'] = clientType
  }

  if (clientVersion) {
    axios.defaults.headers.common['X-Client-Version'] = clientVersion
  }
}

export function setCloudfrontCountry (country: string) {
  axios.defaults.headers.common['cloudfront-viewer-country'] = country
}

export function setApiToken (token: string | null) {
  // console.info('setApiToken', token)
  if (token) {
    axios.defaults.headers.common['X-Spellingshed-Token'] = token
  }
}

function translateKeys (data: { [key: string]: any }, root: string | null, newData: any) {
  Object.keys(data).forEach((key: string) => {
    if (data![key] !== undefined) {
      if (typeof data![key] === 'object') {
        if (data[key] instanceof Date) {
          newData[key] = data[key]
        }
        translateKeys(data![key], key, newData)
      } else if (root === null) {
        newData[key] = String(data![key])
      } else {
        newData[`${root}[${key}]`] = String(data![key])
      }
    }
  })

  return newData
}

function translateKeysOldFormat (data: { [key: string]: any }) {
  const newData: any = {}

  Object.keys(data).forEach((key: string) => {
    if (data![key] !== undefined) {
      newData[key] = String(data![key])
    }
  })

  return newData
}

export async function rawRequest<T = any> (method: Method, url: string, data: any | null = null, responseType: 'arraybuffer' | 'json' | 'text' = 'json', uploadPercentCallback?: (percent: number) => void, token?: string | null, headers: Record<string, string> = {}, useNewQueryFormat = false): Promise<AxiosResponse<T>> {
  if (!_initialised) {
    throw new Error('API client has not been initialised. Please call `initApi` during application bootstrap.')
  }

  const hasRequestBody = method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' || method.toUpperCase() === 'PATCH'
  const hasResponseBody = method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' || method.toUpperCase() === 'PATCH' || method.toUpperCase() === 'DELETE'

  // NodeJS support. Check `Blob` is defined before using it...
  if (typeof Blob !== 'undefined' && data instanceof Blob) {
    headers['content-type'] = data.type
  }

  if (token !== undefined) {
    headers['X-Spellingshed-Token'] = token ?? ''
  }

  if (!hasRequestBody && data) {
    // Necessary to ensure `null` gets encoded as `"null"` which is important for descerning between NULL and absent values
    let newData: any = {}

    if (useNewQueryFormat) {
      translateKeys(data, null, newData)
    } else {
      newData = translateKeysOldFormat(data)
    }

    data = newData
  }

  const axiosConfig: AxiosRequestConfig = {
    method,
    headers,
    url,
    data: hasRequestBody ? data : null,
    params: !hasRequestBody ? data : null,
    responseType
  }

  if (uploadPercentCallback) {
    let previousUploadPercentage = 0
    axiosConfig.onUploadProgress = (progressEvent: ProgressEvent) => {
      const newUploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
      if (previousUploadPercentage !== newUploadPercentage) {
        previousUploadPercentage = newUploadPercentage
        uploadPercentCallback(newUploadPercentage)
      }
    }
  }

  // eslint-disable-next-line no-return-await
  return await axios(axiosConfig)
}

export function isAxiosError (error: AxiosError | any): error is AxiosError<any> {
  return error && error.isAxiosError
}

/** Same as `request` but automatically handles server error responses and throw exceptions */
export async function request (method: Method, url: string, data: {} | null = null, uploadPercentCallback?: (percent: number) => void, token?: string | null, headers: Record<string, string> = {}, useNewQueryFormat = false) {
  try {
    const response = await rawRequest(method, url, data, 'json', uploadPercentCallback, token, headers, useNewQueryFormat)

    if (response.headers['x-api-target'] && _receiveApiTarget) {
      _receiveApiTarget(response.headers['x-api-target'] as ServerEnv)
    }

    // Some API's return errors as 200 OK, so we need to look for these...
    if (response.data && response.data.error) {
      if (response.data.error === 'Incorrect login details') {
        throw new IncorrectLoginError(response.data.error)
      }

      if (response.data.error === 'Parent not setup') {
        throw new IncorrectLoginError(response.data.error)
      }

      throw new HttpResponseError(response.data.error + (response.data.message ? ': ' + response.data.message : ''), 200, response.data)
    }

    return response
  } catch (err: unknown) {
    // Pass this error on (generated above)
    if (err instanceof IncorrectLoginError) {
      throw err
    }

    if (isAxiosError(err)) {
      if (err.response && err.response.status === 422) {
        throw new ValidationError(process.env.NODE_ENV === 'production' ? 'Failed - Invalid data' : err.response.data.message)
      }

      console.error(method, url, '-', err.message, err.response && err.response.data)

      checkForAuthError(err)
      checkForForbiddenError(err)

      if (err.response) {
        throw new HttpResponseError(err.response.data?.message ?? err.message, err.response.status, err.response.data)
      } else {
        throw new Error(`${err.message}`)
      }
    }

    if (err instanceof Error) {
      throw err
    }

    throw err
  }
}

function checkForAuthError (err: AxiosError & { response?: AxiosResponse }) {
  if (err.response && err.response.status === 401) {
    if (_authErrorHandler) {
      _authErrorHandler()

      throw new UnauthorisedError(err.response.data?.message ?? err.message ?? 'Unauthorised')
    }
  }
}

function checkForForbiddenError (err: AxiosError & { response?: AxiosResponse }) {
  if (err.response && err.response.status === 403) {
    throw new AccessDeniedError(err.response.data?.message ?? err.message ?? 'Permission denied')
  }
}

export class UnauthorisedError extends Error {
  constructor (message: string) {
    super(message)

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, UnauthorisedError.prototype)
  }
}

export class IncorrectLoginError extends Error {
  constructor (message: string) {
    super(message)

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, IncorrectLoginError.prototype)
  }
}

export class ValidationError extends Error {
  constructor (message: string) {
    super(message)

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}

export class HttpResponseError extends Error {
  status: number
  data: any

  constructor (message: string, status: number, data: any) {
    super(message)
    this.status = status
    this.data = data

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, HttpResponseError.prototype)
  }
}
