/* eslint-disable camelcase */
import en_au from './en-au'
import en_ca from './en-ca'
import en_gb from './en-gb'
import en_hk from './en-hk'
import en_ie from './en-ie'
import en_in from './en-in'
import en_nz from './en-nz'
import en_pk from './en-pk'
import en_us from './en-us'
import en_za from './en-za'

export const CommonLangs = {
  'en-au': en_au,
  'en-ca': en_ca,
  'en-gb': en_gb,
  'en-hk': en_hk,
  'en-ie': en_ie,
  'en-in': en_in,
  'en-nz': en_nz,
  'en-pk': en_pk,
  'en-us': en_us,
  'en-za': en_za
} as const
