import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {CommonLangs} from '@/edshed-common/i18n/lang'

Vue.use(VueI18n)

const messages = {
  'en-gb': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    Licences: 'Licences',
    licence: 'licence',
    Licence: 'Licence',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County',
    Maths: 'Maths',
    SchoolDistricts: 'Trusts of groups of schools who want to be able to subscribe for several schools.',
    SchemeText: 'Scheme',
    favourite: 'favourite',
    Favourite: 'Favourite',
    favourites: 'favourites',
    Favourites: 'Favourites'
  },
  'en-us': {
    hello: 'hello world',
    organisation: 'organization',
    Organisation: 'Organization',
    district: 'district',
    District: 'District',
    pupil: 'student',
    Pupil: 'Student',
    pupils: 'students',
    Pupils: 'Students',
    licences: 'licenses',
    Licences: 'Licenses',
    licence: 'license',
    Licence: 'License',
    scheme: 'curriculum',
    Postcode: 'Zip',
    County: 'State',
    Maths: 'Math',
    SchoolDistricts: 'School Districts who want to subscribe for more than one school.',
    SchemeText: 'Curriculum',
    favourite: 'favorite',
    Favourite: 'Favorite',
    favourites: 'favorites',
    Favourites: 'Favorites'
  },
  'en-ie': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    SchemeText: 'Scheme',
    favourite: 'favourite',
    Favourite: 'Favourite',
    favourites: 'favourites',
    Favourites: 'Favourites'
  },
  'en-au': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    SchemeText: 'Scheme',
    favourite: 'favourite',
    Favourite: 'Favourite',
    favourites: 'favourites',
    Favourites: 'Favourites'
  },
  'en-nz': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    SchemeText: 'Scheme',
    favourite: 'favourite',
    Favourite: 'Favourite',
    favourites: 'favourites',
    Favourites: 'Favourites'
  }
}

export const i18n = new VueI18n({
  locale: 'en-gb', // set locale
  fallbackLocale: 'en-gb',
  messages: {...CommonLangs} as any
})
